.rounded {
	border-radius: 1rem
}

.nav-pills .nav-link {
	color: #555
}

.nav-pills .nav-link.active {
	color: white;
    background-color: #299be4 !important;
}

input[type="radio"] {
	margin-right: 5px
}

.bold {
	font-weight: bold
}

.preview {
    display: none;
}
